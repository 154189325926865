import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    var i = <div/>
    if (post.frontmatter.cover_image){
      i = <div>
            <Img 
              fluid={post.frontmatter.cover_image.childImageSharp.fluid}
              alt={post.frontmatter.cover_image_caption}  
            />
            <figcaption className="text-center text-sm text-gray-700 font-light">{post.frontmatter.cover_image_caption}</figcaption>
          </div>
    }

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO 
          title={post.frontmatter.title}
          description={post.excerpt}
          keywords={post.frontmatter.keywords}
        />
        <div className="flex flex-wrap max-w-5xl mx-auto">
          <div className="container w-full lg:w-3/4 p-3">
            <div className="">
              <div className="md:w-full mx-auto">{i}</div>
            </div>
            <h1 className="text-center leading-tight border-b text-4xl font-semibold mb-4 mt-4 pb-2">{post.frontmatter.title}</h1>
            <div className="flex justify-center items-center my-5">
              <Img
                fixed={this.props.data.avatar.childImageSharp.fixed}
                alt={this.props.data.site.siteMetadata.author}
                className="rounded-full w-1/3 border-solid border-white border-2"
              />
              <div className="leading-none font-light text-grey-dark w-2/3 mb-4 ml-3">
                <small>
                  <em>
                    Updated by {post.frontmatter.author}.{` `}
                    {post.frontmatter.author_bio}
                  </em>
                </small>
              </div>
            </div>
            <section
              className="simple-post"
              dangerouslySetInnerHTML={{ __html: post.html }}
            />
            <div className="mb-4">
              <h6>
              Tags:
              {post.fields.tags.map(tag => (
                <Link
                  to={`/tags/${tag}`}
                >
                  {` `}{tag}
                </Link>
              ))}
              </h6>
            </div>
            <hr/>
                         
            <ul style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              listStyleType: `none`,
              padding: 0,}}
            >
            <li style={
              { listStyleType: `none`, 
                padding: 0,
              }
            }>
              {previous && (
                <Link to={previous.fields.slug} rel="prev">
                  ← {previous.frontmatter.title}
                </Link>
              )}
            </li>
            <li style={
              { listStyleType: `none`, 
                padding: 0,
              }
            }>
              {next && (
                <Link to={next.fields.slug} rel="next">
                  {next.frontmatter.title} →
                </Link>
              )}
            </li>
            </ul>  
          </div>
          <div className="container w-full lg:w-1/4 p-3">
            <Bio />
          </div>
        </div>
        
      </Layout>
    )
  }
}

export default BlogPostTemplate

/*

<p className="author"><small>
              <div className="text-center mb-4">
                <em>
                  Updated by {post.frontmatter.author}.{` `}
                  {post.frontmatter.author_bio}
                </em>
              </div>
            </small></p>
            */

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    avatar: file(absolutePath: { regex: "/me_hkg__43_750.jpg/" }) {
      childImageSharp {
        fixed(width: 66, height: 66) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        cover_image {
          childImageSharp {
            resize (width:750, height: 750) {
              src
            }
            fluid (maxWidth: 786) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        cover_image_caption
        keywords
        author
        author_bio
      }
      fields {
        tags
      }
    }
  }
`
